import roelvdven from "../images/people/roel_van_de_ven.jpg";
import hansgiesbers from "../images/people/hans_giesbers_1.jpg";
import suzannebakx from "../images/people/Suzanne_Bakx_1.jpg";
import bisbhola from "../images/people/Bis_Bhola_2.jpg";
import dannypeeters from "../images/people/danny_peeters.jpg";
import guidodenobel from "../images/people/Guido_de_Nobel_3.jpg";
import liesbethtillemans from "../images/people/liesbeth_tillemans.jpg";
import christianpilon from "../images/people/christian_pilon.jpg";
import dennisvanotterloo from "../images/people/Dennis_van_Otterloo_1.jpg";
import martinwigchert from "../images/people/Martin_Wigchert_2.jpg";
import dilanvanson from "../images/people/dilan_van_son.jpg";
import willemarnoldussen from "../images/people/Willem_Arnoldusse_3.jpg";
import erwinvanliempt from "../images/people/erwin_van_liempt.jpg";
import lottehoppen from "../images/people/lotte_hoppen.jpg";
import mauricegelden from "../images/people/Maurice Gelden-2 new.jpg";
import mvdendobbelsteen from "../images/people/Marcel_van_den_Dobbelsteen_5.jpg";
import elmaribari from "../images/people/Elma_Ribari_4.jpg";
import janhuijgers from "../images/people/Jan_Huijgers_1.jpg";
import micheljongen from "../images/people/Michel_Jongen_2.jpg";
import renelaan from "../images/people/rene_laan.jpg";
import leviwigchert from "../images/people/Levi_Wigchert_4.jpg";
import svdbuijs from "../images/people/Sjef_Van_den_Buys_3.jpg";
import hansvanalem from "../images/people/Hans_van_Alem_2.jpg";
import erikrovers from "../images/people/Erik_Rovers_4.jpg";
import kristelsnepvangers from "../images/people/kristel_snepvangers.jpg";
import paulmensink from "../images/people/Paul_Mensink_1.jpg";
import harryhol from "../images/people/Harry_Hol_1.jpg";
import eddoverdoner from "../images/people/Eddo_Verdoner_1.jpg";
import roelandedelbroek from "../images/people/Roeland_Edelbroek_1.jpg";
import wijnandybema from "../images/people/Wijnand_Ybema_2.jpg";
import thomasjanssen from "../images/people/Thomas_Janssen_2.jpg";
import anneraijmakers from "../images/people/anne_raijmakers.jpg";
import teamservie from "../images/people/Pica team Servie.jpg";


export default {
    people: [
        {
            name: "Roel van de Ven",
            firstName: "Roel",
            lastName: "van de Ven",
            function: "",
            image: roelvdven,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/roelvdven",
            },
        }, {
            name: "Harry Hol",
            firstName: "Harry",
            lastName: "Hol",
            function: "Consultant",
            image: harryhol,
            contact: {
                phone: "+31 (0)6 42773583",
                email: "harry.hol@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/harryhol",
            },
        }, {
            name: "Suzanne Bakx",
            firstName: "Suzanne",
            lastName: "Bakx",
            function: "",
            image: suzannebakx,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/suzanne-bakx-07973459",
            },
        }, {
            name: "Bis Bhola",
            firstName: "Bis",
            lastName: "Bhola",
            function: "Consultant",
            image: bisbhola,
            contact: {
                phone: "+31 (06) 10386901",
                email: "bis.bhola@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/bis-bhola-884b335/",
            },
        }, {
            name: "Danny Peeters",
            firstName: "Danny",
            lastName: "Peeters",
            function: "",
            image: dannypeeters,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/danny-peeters-a2a4197/",
            },
        }, {
            name: "Guido de Nobel",
            firstName: "Guido",
            lastName: "de Nobel",
            function: "Consultant",
            image: guidodenobel,
            contact: {
                phone: "+31 (0)6 26925115",
                email: "guido.de.nobel@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/guido-de-nobel",
            },
        }, {
            name: "Liesbeth Tillemans",
            firstName: "Liesbeth",
            lastName: "Tillemans",
            function: "",
            image: liesbethtillemans,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/liesbethtillemans",
            },
        }, {
            name: "Christian Pilon",
            firstName: "Christian",
            lastName: "Pilon",
            function: "Relatiemanager",
            image: christianpilon,
            contact: {
                phone: "+31 (0)6 12427161",
                email: "christian.pilon@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/christian-pilon-1aa2651",
            },
        }, {
            name: "Dennis van Otterloo",
            firstName: "Dennis",
            lastName: "van Otterloo",
            function: "",
            image: dennisvanotterloo,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/dvotterloo/",
            },
        }, {
            name: "Martin Wigchert",
            firstName: "Martin",
            lastName: "Wigchert",
            function: "",
            image: martinwigchert,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/wigchert",
            },
        }, {
            name: "Dilan van Son",
            firstName: "Dilan",
            lastName: "van Son",
            function: "",
            image: dilanvanson,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/dilanvanson",
            },
        }, {
            name: "Willem Arnoldussen",
            firstName: "Willem",
            lastName: "Arnoldussen",
            function: "Consultant",
            image: willemarnoldussen,
            contact: {
                phone: "+31 (0)6 50419207",
                email: "willem.arnoldussen@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/willem-arnoldussen-b4559514",
            },
        }, {
            name: "Erwin van Liempt",
            firstName: "Erwin",
            lastName: "van Liempt",
            function: "",
            image: erwinvanliempt,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/erwinvanliempt-hogenes",
            },
        }, {
            name: "Lotte Hoppen",
            firstName: "Lotte",
            lastName: "Hoppen",
            function: "",
            image: lottehoppen,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/lotte-hoppen-6ab016108",
            },
        }, {
            name: "Maurice Gelden",
            firstName: "Maurice",
            lastName: "Gelden",
            function: "Consultant",
            image: mauricegelden,
            contact: {
                phone: "+31 (0)6 20531202",
                email: "maurice.gelden@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/gelden",
            },
        }, {
            name: "Marcel van den Dobbelsteen",
            firstName: "Marcel",
            lastName: "van den Dobbelsteen",
            function: "",
            image: mvdendobbelsteen,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/marcelvandendobbelsteen",
            },
        }, {
            name: "Elma Ribari",
            firstName: "Elma",
            lastName: "Ribari",
            function: "",
            image: elmaribari,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/elma-ribari",
            },
        }, {
            name: "Jan Huijgers",
            firstName: "Jan",
            lastName: "Huijgers",
            function: "Consultant",
            image: janhuijgers,
            contact: {
                phone: "+31 (0)6 55156801",
                email: "jan.huijgers@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/jan-huijgers",
            },
        }, {
            name: "Michel Jongen",
            firstName: "Michel",
            lastName: "Jongen",
            function: "",
            image: micheljongen,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/michel-jongen-a297708",
            },
        }, {
            name: "René Laan",
            firstName: "René",
            lastName: "Laan",
            function: "",
            image: renelaan,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/renelaanddc",
            },
        }, {
            name: "Levi Wigchert",
            firstName: "Levi",
            lastName: "Wigchert",
            function: "",
            image: leviwigchert,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/leviwigchert",
            },
        }, {
            name: "Sjef van den Buijs",
            firstName: "Sjef",
            lastName: "van den Buijs",
            function: "",
            image: svdbuijs,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/sjefvandenbuys",
            },
        }, {
            name: "Hans van Alem",
            firstName: "Hans",
            lastName: "van Alem",
            function: "",
            image: hansvanalem,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/hansvanalem",
            },
        }, {
            name: "Erik Rovers",
            firstName: "Erik",
            lastName: "Rovers",
            function: "",
            image: erikrovers,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/erik-rovers-b28a64",
            },
        }, {
            name: "Kristel van den Broek",
            firstName: "Kristel",
            lastName: "van den Broek",
            function: "Consultant",
            image: kristelsnepvangers,
            contact: {
                phone: "+31 (0)6 28640967",
                email: "kristel.van.den.broek@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/kristel-snepvangers-2753a613",
            },
        }, {
            name: "Paul Mensink",
            firstName: "Paul",
            lastName: "Mensink",
            function: "",
            image: paulmensink,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/paul-mensink-8b1b3210/",
            },
        }, {
            name: "Hans Giesbers",
            firstName: "Hans",
            lastName: "Giesbers",
            function: "Relatiemanager",
            image: hansgiesbers,
            contact: {
                phone: "+31 (0)6 20 488531",
                email: "hans.giesbers@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/hans-giesbers",
            },
        }, {
            name: "Eddo Verdoner",
            firstName: "Eddo",
            lastName: "Verdoner",
            function: "Consultant",
            image: eddoverdoner,
            contact: {
                phone: "+31 (0)6 22999162",
                email: "eddo.verdoner@ddcgroup.com",
                linkedin: "https://www.linkedin.com/in/eddoverdoner/",
            },
        }, {
            name: "Roeland Edelbroek",
            firstName: "Roeland",
            lastName: "Edelbroek",
            function: "",
            image: roelandedelbroek,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/roelandedelbroek",
            },
        }, {
            name: "Wijnand Ybema",
            firstName: "Wijnand",
            lastName: "Ybema",
            function: "",
            image: wijnandybema,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/wijnand-ybema-79a0a91",
            },
        }, {
            name: "Thomas Janssen",
            firstName: "Thomas",
            lastName: "Janssen",
            function: "",
            image: thomasjanssen,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/thomas-janssen-aa4775154",
            },
        }, {
            name: "Anne Raijmakers",
            firstName: "Anne",
            lastName: "Raijmakers",
            function: "",
            image: anneraijmakers,
            contact: {
                phone: "",
                email: "",
                linkedin: "https://www.linkedin.com/in/anne-raijmakers/",
            },
        }, {
            name: "Team Servië",
            firstName: "Team",
            lastName: "Servië",
            function: "",
            image: teamservie,
            contact: {
                phone: "",
                email: "",
                linkedin: "",
                link: "/remote-devops-team"
            },
        }
    ]
}
